.sidebar .nav-link {
  display: flex !important;
  align-items: center;
}

.sidebar .nav-link .badge {
  margin-left: auto;
}

.badge {
  border-radius: 27px !important;
  padding: 3px 6px !important;
  font-weight: 600 !important;
  font-size: 11px !important;
}

.badge.badge-FREE {
  background-color: #59c9a5;
}
.badge.badge-PLUS {
  background-color: #5967f2;
}
.badge.badge-PRO {
  background-color: #bf3664;
}
.badge.badge-ULTIMATE {
  background-color: #faa633;
}

.badge-step {
  background-color: #27282a;
  transition: none !important;
}

.nav-link:hover .badge-step {
  background-color: #2877a2;
}

html:not([dir='rtl']) .sidebar {
  margin-left: -230px !important;
}

html:not([dir='rtl']) .sidebar-show .sidebar,
html:not([dir='rtl']) .sidebar-show .sidebar {
  margin-left: 0 !important;
}

.sidebar .nav {
  width: 235px !important;
}

.sidebar .sidebar-nav {
  width: 230px !important;
}

.just-icon {
  background-color: #73818f;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 15px;
}

.sidebar .nav-link:hover .just-icon {
  background-color: #fff;
}

.custom-testimonial-lang {
  max-width: 120px;
}

.ebay-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/ebay-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/ebay-icon.svg') no-repeat 50% 50%;
}

.nav-link.active .just-icon {
  background-color: #20a8d8;
}

.reviewsco-icon {
  -webkit-mask: url('./assets/img/brand/reviewsco.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/reviewsco.svg') no-repeat 50% 50%;
}

.trusted-shops-icon {
  -webkit-mask: url('./assets/img/brand/trustedshops.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/trustedshops.svg') no-repeat 50% 50%;
}

.allegro-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/allegro-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/allegro-icon.svg') no-repeat 50% 50%;
}

.trustpilot-icon {
  -webkit-mask: url('./assets/img/brand/trustpilot.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/trustpilot.svg') no-repeat 50% 50%;
}

.yell-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/yell.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/yell.svg') no-repeat 50% 50%;
}

.booksy-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/booksy-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/booksy-icon.svg') no-repeat 50% 50%;
}

.google-shopping-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/google-shopping-icon.svg') no-repeat 50%
    50%;
  mask: url('./assets/img/brand/google-shopping-icon.svg') no-repeat 50% 50%;
}

.booking-com-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/booking.com-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/booking.com-icon.svg') no-repeat 50% 50%;
}

.airbnb-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/airbnb-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/airbnb-icon.svg') no-repeat 50% 50%;
}

.walmart-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/walmart-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/walmart-icon.svg') no-repeat 50% 50%;
}

.aliexpress-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/aliexpress-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/aliexpress-icon.svg') no-repeat 50% 50%;
}

.capterra-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/capterra-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/capterra-icon.svg') no-repeat 50% 50%;
}

.g2crowd-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/g2crowd-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/g2crowd-icon.svg') no-repeat 50% 50%;
}

.product-hunt-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/producthunt-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/producthunt-icon.svg') no-repeat 50% 50%;
}

.yellow-pages-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/yellowpages-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/yellowpages-icon.svg') no-repeat 50% 50%;
}

.ekomi-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/ekomi-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/ekomi-icon.svg') no-repeat 50% 50%;
}

.sitejabber-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/sitejabber-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/sitejabber-icon.svg') no-repeat 50% 50%;
}

.play-google-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/play.google-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/play.google-icon.svg') no-repeat 50% 50%;
}

.apple-store-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/apps.apple-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/apps.apple-icon.svg') no-repeat 50% 50%;
}

.angie-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/angie-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/angie-icon.svg') no-repeat 50% 50%;
}

.thumbtack-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/thumbtack-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/thumbtack-icon.svg') no-repeat 50% 50%;
}

.zillow-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/zillow-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/zillow-icon.svg') no-repeat 50% 50%;
}

.avvo-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/avvo-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/avvo-icon.svg') no-repeat 50% 50%;
}

.martindale-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/martindale-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/martindale-icon.svg') no-repeat 50% 50%;
}

.opentable-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/opentable-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/opentable-menu-icon.svg') no-repeat 50% 50%;
}

.vitals-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/vitals-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/vitals-menu-icon.svg') no-repeat 50% 50%;
}

.zomato-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/zomato-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/zomato-menu-icon.svg') no-repeat 50% 50%;
}

.ratemds-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/ratemds-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/ratemds-menu-icon.svg') no-repeat 50% 50%;
}

.healthgrades-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/healthgrades-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/healthgrades-menu-icon.svg') no-repeat 50% 50%;
}

.zocdoc-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/zocdoc-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/zocdoc-menu-icon.svg') no-repeat 50% 50%;
}

.webmd-icon {
  width: 13px;
  -webkit-mask: url('./assets/img/brand/menu-icons/webmd-menu-icon.svg') no-repeat 50% 50%;
  mask: url('./assets/img/brand/menu-icons/webmd-menu-icon.svg') no-repeat 50% 50%;
}


.ps__rail-y,
.ps__rail-x {
  display: none;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  display: none;
}

#hubspot-messages-iframe-container {
  z-index: 997 !important;
}

.justreview-spinner {
  display: none !important;
}

.sk-spinner-pulse-widget {
  margin: 0 auto !important;
}

@media (max-width: 575px) {
  .main .container-fluid {
    padding: 0 15px !important;
  }

  .card {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .sidebar {
    width: 230px !important;
  }
}

@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: 230px !important;
  }

  .sidebar {
    width: 230px !important;
  }

  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 230px !important;
  }

  html:not([dir='rtl']) .sidebar-lg-show .sidebar,
  html:not([dir='rtl']) .sidebar-show .sidebar {
    margin-left: 0 !important;
  }
}
