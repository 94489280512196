// Here you can add other styles

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');

@import url('https://justreview.co/widget/justreview.css');

.loading-wrapper {
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connector-loader {
  font-size: 16px;
  font-weight: bold;
}

.connector-loader__animation {
  margin: 15px auto 40px;
}

.slider {
  margin: 15px auto 40px;
  position: absolute;
  width: 280px;
  height: 5px;
  overflow-x: hidden;
  top: 15px;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #4a8df8;
  width: 150%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #4a8df8;
  height: 5px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

.logo-wrapper {
  max-width: 1300px;
  display: flex;
  flex-wrap: wrap;
}

.radius-wrapper {
  display: flex;
  flex-direction: column;
  height: 149px;
  flex-wrap: wrap;
}

.radius-wrapper > div {
  padding-right: 40px;
}

.btn-help-code {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.upgrade-card-name {
  text-align: center;
  margin-right: 10px;
}

.ReviewsCardHeader .PlanSticker {
  margin-left: 10px;
}

.ReviewsCardHeader .PlanSticker.FREE {
  background-color: #59c9a5;
}
.ReviewsCardHeader .PlanSticker.PLUS {
  background-color: #5967f2;
}
.ReviewsCardHeader .PlanSticker.PRO {
  background-color: #bf3664;
}
.ReviewsCardHeader .PlanSticker.ULTIMATE {
  background-color: rgb(250, 166, 51);
}

.margin-top-50 {
  margin-top: -50px;
}

.custom-collapse .card {
  background: transparent;
  border: none;
}

.custom-collapse .card-body {
  padding-left: 0;
}

.custom-testimonial-url {
  margin-top: 1px;
  max-width: 308px;
}

.card-body-custom-testimonial-url {
  padding-top: 0;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.flex-col {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.submitBtn > div {
  padding: 0px 7px 0 0 !important;
}

.dropdown .counter-badge {
  position: static;
}

.plan-sticker-dropdown,
.opinion-counter-dropdown {
  display: none;
  padding: 5px 10px;
}

.plan-sticker-dropdown {
  padding-top: 0;
}

@media all and (max-width: 976px) {
  .radius-wrapper {
    height: 210px;
  }
}

@media all and (max-width: 777px) {
  .plan-sticker-dropdown,
  .opinion-counter-dropdown {
    display: block;
  }

  .navbar .PlanSticker {
    display: none;
  }

  .dropdown .PlanSticker {
    display: inline-block;
  }

  .header-link-counter {
    display: none;
  }

  .navbar-nav .ButtonUpgrade {
    margin-right: 0;
  }

  .navbar-nav .ButtonUpgrade:after {
    display: none;
  }
}

@media all and (max-width: 652px) {
  .radius-wrapper {
    height: auto;
  }
}

@media all and (max-width: 500px) {
  .btn-help-code {
    position: static;
    margin-bottom: 10px;
  }

  .navbar-nav .ButtonUpgrade {
    max-width: 155px;
  }

  .justify-content-center {
    justify-content: flex-start !important;
  }
}

.sidebar .nav-dropdown.open > .nav-dropdown-items {
  max-height: 1700px;
}

.serviceInputs label {
  margin-bottom: 1rem;
}
.collecting__text {
  margin-top: 15px;
  max-width: 300px;
  text-align: left !important;
}

.cron-text {
  margin-top: 15px;
  text-align: left !important;
}

.collecting__progress-bar {
  max-width: 400px;
}

.justreview-badge {
  z-index: 10;
}

.modal-dialog {
  z-index: 11;
}
